body {
  color: black;
  background: #003f00;
  font-family: Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Georgia, Times, Palatino, serif;
  color: black;
}

h1 {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 2px solid green;
  line-height: 1.3;
}
h2 {
  text-align: left;
  padding-top: 0.5em;
  padding-left: 2em;
}
h5, h6 {
  font-size: 1.0em;
}

div.main {
  color: black;
  background: #bfffbf;
  margin-top: 1em;
  margin-left: 10%;
  margin-right: 10%;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  border-radius: 10px;
  padding-top: 0em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
}

hr {
  border: 1px solid darkgreen;
}

a {
  color: orange;
  font-weight: bold;
  text-decoration: none;
}
a:visited {
  color: orange;
  font-weight: bold;
  text-decoration: none;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
p.footer {
  text-align: right;
  color: darkgreen;
  margin-top: 2em;
  margin-bottom: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
}

pre {
  padding: 1em;
  margin: 0;
}

div.content {
  padding: 0;
}

div.title {
  color: black;
  font-weight: bold;
  text-align: left;
  margin-top: 1.0em;
  margin-bottom: 0.5em;
}

div.code {
  font-family: monospace;
  border: 1px solid #dddddd;
  border-left: 5px solid #f0f0f0;
  background: #f8f8f8;
  padding: 0.5em;
}

dl {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
dt {
  margin-top: 0.5em;
  margin-bottom: 0;
  font-style: normal;
  color: black;
}

ol {
  list-style-position: outside;
  list-style-type: decimal;
}

ul {
  list-style-type: none;
}

.comment {
  background: yellow;
}

.current {
  color: yellow;
  font-weight: bold;
}
